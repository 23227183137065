import {
    put,
    takeLatest
} from 'redux-saga/effects';
import axios from 'axios';
import Toast from '../../components/Toast';
import getToken from '../../mixins/GetToken';
import SplitToken from '../../mixins/SplitToken';

function* userLogin(action) {
    const token = getToken();
    const userToken = SplitToken();
    try {
        yield put({
            type: "USER_LOADING",
            payload: true
        })
        const login = {
            Username: action.payload.UserName,
            Password: action.payload.Password
        };
        let user = yield axios.post('/api/Account/GetToken', login, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'https://*, http://*'
            }
        });
        if (user.status == 200){
            const token = JSON.stringify(user.data)
            sessionStorage.setItem('auth', token)
            yield put({
                type: "USER_DATA",
                payload: user.data.id
            })
            yield put({
                type: 'IS_AUTHENTICATED',
                payload: user.data
            })
            yield put({
                type: "FETCH_PROCESSOR_VERSION"
            });
            // yield put({
            //     type: "USER_TOKEN",
            //     payload: user.data.token
            // })
            yield put({
                type: "USER_LOADING",
                payload: false
            })
        }
    } catch (error) {
        Toast.fire({
            icon: 'error',
            title: `Oops, something went wrong, double check your username and or password.`,
        });
        yield put({
            type: "USER_LOADING",
            payload: false
        })
        return console.log(error)
    }
};

function* getUserData(action) {
    const token = getToken();
    const userToken = SplitToken();
    console.log("TOKEN", token)

    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };

    // console.log("ACTION", action.payload)
    let companyId = ''
    try {
        const userCompany = yield axios.get(`/api/Company/GetAllCompaniesForUser/${action.payload}`, config);
        // console.log("LOOK HERE", userCompany)
        yield put({
            type: "USER_COMPANIES",
            payload: userCompany.data
        })
        yield put({
            type: "GET_COMPANY_USERS",
            payload:  {event: userCompany.data[0].id , type:1}
        })
        companyId = userCompany.data[0].id
    } catch (error) {
        console.log(`Error getting companies: ${error}`)
    }
    try {
        const userInfo = yield axios.get(`/api/Account/GetUserInfo/${action.payload}`, config)
        yield put({
            type: "SET_USER",
            payload: userInfo.data
        })
    } catch (error) {
        return console.log(`Error grabbing user info: ${error}`)
    }
    try {
        const roles = yield axios.get('/api/Account/GrabAllRoles', config);
        if (roles.status== 200){
            console.log("YES")
        }
        yield put({
            type: "SET_ALL_ROLES",
            payload: roles.data
        })
    } catch (error) {
        console.log(`Error grabbing roles: ${error}`)
    }
    try {
        const userRole = yield axios.get(`/api/Account/GrabUserRole/${action.payload}`, config);
        yield put({
            type: "SET_USER_ROLE",
            payload: userRole.data
        })
    } catch (error) {
        console.log(`Error grabbing user role: ${error}`);
    }
    yield put({
        type: 'GET_COMPANY_FILES',
        payload: companyId,
    })
    yield put({
        type: "FETCH_MESSAGES",
        payload: companyId
    })
    yield put({
        type: 'GET_CUSTOMER_FILES',
        payload: {companyId: companyId, page: 0, pageSize: 25}
    })
    yield put({
        type: "FETCH_S_A_CONF",
    });
    yield put({
        type: 'GET_ALL_APPS',
    });
}

function* userLogout(action) {
    const token = getToken();
    const userToken = SplitToken();
    yield put({
        type: 'IS_AUTHENTICATED',
        payload: false
    })
    sessionStorage.removeItem("auth");
    window.location.reload();
};

function* userCreation(action) {
    const token = getToken();
    const userToken = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        const newUser = yield axios.post('/api/Account/CreateUser', action.payload, config)

        if (newUser.status === 200) {
            const info = JSON.stringify(newUser.data)
            sessionStorage.setItem('pickUser', info);

            Toast.fire({
                icon: 'success',
                title: `Successfully registered account, next please verify email address.`,
                // delay: 6000,
            });
        }
        return console.log(newUser);

    } catch (error) {
        Toast.fire({
            icon: 'error',
            title: `Oops, something went wrong or Email may already be in use.`,
            // delay: 6000,
        });
        return console.log(error);
    }
};

function* userRegistration(action) {
    const token = getToken();
    const userToken = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        const newUser = yield axios.post('/api/Account/RegisterNewUser', action.payload, config)

        if (newUser.status === 200) {
            const info = JSON.stringify(newUser.data)
            sessionStorage.setItem('pickUser', info);

            Toast.fire({
                icon: 'success',
                title: `Successfully registered account, next please verify email address.`,
                // delay: 6000,
            });
            // navigate('/', { replace: true });
        }
        return console.log(newUser);

    } catch (error) {
        Toast.fire({
            icon: 'error',
            title: `Oops, something went wrong or Email may already be in use.`,
            // delay: 6000,
        });
        return console.log(error);
    }
};

function* confirmEmail(action) {
    const token = getToken();
    const userToken = SplitToken();
    try {
        const confirm = yield axios.get(`/api/Account/ConfirmEmail/${action.payload}`);
        console.log(confirm)
        if (confirm.status === 200) {
            Toast.fire({
                icon: 'success',
                title: `Please Login to continue.`,
                // delay: 5000,
            });
            action.navigate('/');
        }
    } catch (error) {
        console.log(error)
    }
};

function* requestPassword(action) {
    const token = getToken();
    const userToken = SplitToken();
    try {
        console.log("EMAIL HERE", action.payload);
        const responseUser = yield axios.post(`/api/Account/RequestPassword`, action.payload);
        if (responseUser.status === 200) {
            Toast.fire({
                icon: 'success',
                title: `Check your email to recover password.`,
                // delay: 5000,
            });
            action.navigate('/');
        }
    } catch (error) {
        console.log('error in request password saga.', error);
    }
};

function* ForgotPassword(action) {
    const token = getToken();
    const userToken = SplitToken();
    // console.log('change password saga');
    let responseUser = "";
    try {
        responseUser = yield axios.post(`api/Account/ForgotPassword`, action.payload);
        if (responseUser.status === 200) {
            Toast.fire({
                icon: 'success',
                title: `Password successfully updated.`,
                // delay: 5000,
            });
            action.navigate('/');
        }
    } catch {
        console.log('error in forgot password saga.');
        Toast.fire({
            icon: 'error',
            title: 'Please Check Your Password'
        });
    }
};

function* requestProcessor(action) {
    const token = getToken();
    const userToken = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        const responseUser = yield axios.post(`api/Message/CreateMessage`, action.payload, config);
        if (responseUser.status === 200) {
            console.log("Message sent")
            action.ModalToggle();
            Toast.fire({
                icon: 'success',
                title: `Processor Reqest Sent.`
            });
        }
    } catch {
        Toast.fire({
            icon: 'error',
            title: 'Message not sent, please try again.'
        });
    }
};

function* updateUserStatus(action) {
    const token = getToken();
    const userToken = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        const responseUser = yield axios.post(`api/Message/UpdateMessage`, action.payload, config);
        console.log(responseUser.status)
        if (responseUser.status === 200) {
            yield put({
                type: "FETCH_MESSAGES",
                payload: action.payload.companyId
            });
            console.log("User Status Updated")
            action?.handleClose1();
            action?.handleClose2();
            if (action.one != null) {
                Toast.fire({
                    icon: 'success',
                    title: `Customer Status Updated.`
                });
            } else if (action.two != null) {
                Toast.fire({
                    icon: 'success',
                    title: `Reply to Customer Sent.`
                });
            }
        }
    } catch (exception) {
        console.log("EXCEPTION", exception)
        Toast.fire({
            icon: 'error',
            title: 'Customer status not updated, please try again.'
        });
    }
};

function* updateAgreementStatus(action) {
    const token = getToken();
    const userToken = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        const responseUser = yield axios.post(`api/Account/UpdatePDFStatus`, action.payload, config);
        if (responseUser.status === 200) {
            try {
                const userInfo = yield axios.get(`/api/Account/GetUserInfo/${userToken.id}`, config)
                yield put({
                    type: "SET_USER",
                    payload: userInfo.data
                })
            } catch (error) {
                return console.log(`Error grabbing user info: ${error}`)
            }
            // action.handleClose1();
            Toast.fire({
                icon: 'success',
                title: `Agreement Accepted.`
            });
        }
    } catch {
        Toast.fire({
            icon: 'error',
            title: 'Agreement status not updated, please try again.'
        });
    }
};

function* fetchMessagesforUser(action) {
    const token = getToken();
    const userToken = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        let allUserMessages = yield axios.get(`/api/Message/GetAllMessagesForUser/${action.payload}`, config);
        yield put({
            type: "USER_MESSAGES",
            payload: allUserMessages.data
        });
        console.log("HERE", allUserMessages.data)

    } catch (error) {
        console.log(error)
    }
};

function* updateUserInfo(action) {
    const token = getToken();
    const userToken = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        let userInfo = yield axios.post(`/api/Account/UpdateUserInfo/${action.payload.userId}`, action.payload.user, config);
        console.log("HERE", userInfo.data)
        if (userInfo.status === 200) {
            try {
                const userInfo = yield axios.get(`/api/Account/GetUserInfo/${userToken.id}`, config)
                yield put({
                    type: "SET_USER",
                    payload: userInfo.data
                })
            } catch (error) {
                return console.log(`Error grabbing user info: ${error}`)
            }
            Toast.fire({
                icon: 'success',
                title: `Account Updated.`
            });
        }
    } catch (error) {
        Toast.fire({
            icon: 'error',
            title: 'Account not updated check your entry and try again.'
        });
        console.log(error)
    }
};

function* updateUserPassword(action) {
    const token = getToken();
    const userToken = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        let userInfo = yield axios.post(`/api/Account/UpdateUserPassword/${action.payload.userId}`, action.payload.user, config);
        console.log("HERE", userInfo.data)
        if (userInfo.status === 200) {
            try {
                const userInfo = yield axios.get(`/api/Account/GetUserInfo/${userToken.id}`, config)
                yield put({
                    type: "SET_USER",
                    payload: userInfo.data
                })
            } catch (error) {
                return console.log(`Error grabbing user info: ${error}`)
            }
            Toast.fire({
                icon: 'success',
                title: `Password Updated.`
            });
        }
    } catch (error) {
        Toast.fire({
            icon: 'error',
            title: 'Password not updated check your entry and try again.'
        });
        console.log(error)
    }
};

function* updateUserRole(action){
    const token = getToken();
    const userToken = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try{
        let userRole = yield axios.post(`/api/Account/UpdateUserRole`, action.payload, config);
        if (userRole.status === 200) {
            Toast.fire({
                icon: 'success',
                title: `Role Updated.`
            });
            yield put({
                type: "GET_COMPANY_USERS",
                payload: {event:action.payload.CompanyId , type:1}
            });
        }
    } catch (error){
        Toast.fire({
            icon: 'error',
            title: `User Role not updated check your entry and try again: ${error.response?.data}`
        });
        console.log(error)
    }
}

function* deactivateEmployee(action){
    const token = getToken();
    const userToken = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try{
        let userRole = yield axios.post(`/api/Account/DeactivateEmployee`, action.payload, config);
        if (userRole.status === 200) {
            Toast.fire({
                icon: 'success',
                title: `Employee Updated.`
            });
            yield put({
                type: "GET_COMPANY_USERS",
                payload: {event:action.payload.CompanyId , type:1}
            });
        }
    } catch (error){
        Toast.fire({
            icon: 'error',
            title: 'Employee not updated try again.'
        });
        console.log(error)
    }
}

function* fetchSAConfig(action){
    const token = getToken();
    const userToken = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try{
        let saConfRes = yield axios.get(`/api/Account/FetchSAConf`, config);
        if (saConfRes.status === 200) {
            yield put({
                type: "SET_S_A_CONF",
                payload: saConfRes.data
            });
        }
    } catch (error){
        console.log(error)
    }
}

function* editSAConfig(action){
    const token = getToken();
    const userToken = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try{
        let saConfRes = yield axios.put(`/api/Account/UpdateSAConfig`, action.payload, config);
        if (saConfRes.status === 200) {
            yield put({
                type: "FETCH_S_A_CONF",
            });
        }
    } catch (error){
        console.log(error)
    }
}

function* UserSaga() {
    yield takeLatest("USER_LOGIN", userLogin);
    yield takeLatest("USER_DATA", getUserData);
    yield takeLatest("USER_LOGOUT", userLogout);
    yield takeLatest("CREATE_USER", userCreation);
    yield takeLatest("CONFIRM_EMAIL", confirmEmail);
    yield takeLatest("REQUEST_PASSWORD", requestPassword);
    yield takeLatest("FORGOT_PASSWORD", ForgotPassword);
    yield takeLatest("REQUEST_PROCESSOR", requestProcessor);
    yield takeLatest("EDIT_CUSTOMER_STATUS", updateUserStatus);
    yield takeLatest("UPDATE_AGREEMENT", updateAgreementStatus);
    yield takeLatest("FETCH_USER_MESSAGES", fetchMessagesforUser);
    yield takeLatest("UPDATE_USER_INFO", updateUserInfo);
    yield takeLatest("UPDATE_USER_PASSWORD", updateUserPassword);
    yield takeLatest("EDIT_EMPLOYEE_ROLE", updateUserRole);
    yield takeLatest("DEACTIVATE_EMPLOYEE", deactivateEmployee);
    yield takeLatest("REGISTER_USER", userRegistration);
    yield takeLatest("FETCH_S_A_CONF", fetchSAConfig);
    yield takeLatest("EDIT_S_A_CONF", editSAConfig);
};

export default UserSaga;