import React from "react";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    GridToolbarDensitySelector,
    GridToolbarExport,
} from "@mui/x-data-grid";
import {
    Box,
    Hidden,
    Button,
    Typography,
    Chip,    
    Modal,
} from "@mui/material";
import {
    CreditCard,
    CheckCircle,
    Loader,
    FileText,
    Edit2,
    AlertCircle,
    Edit, 
    RefreshCw
} from 'react-feather';
import { useDispatch, useSelector } from "react-redux";
import ReviewPage from "../billing/Validation";
import { Form, Label, Input } from 'reactstrap';
import './plans.css';
import PaymentSummary from "../billing/PaymentSummary";
import BotmarkEstimateView from "../billing/BotmarkEstimateView";
import getToken from '../../mixins/GetToken';
import SplitToken from '../../mixins/SplitToken';
import { useRouteLoaderData } from "react-router";
import StaticValidationView from './StaticValidationView';

// const userToken = SplitToken();
// const token = getToken();

const PlanTable = () => {
    /// displays all validated plans for a processor
    const dispatch = useDispatch();
    const userRole = useSelector((state) => state.UserReducer.isAuthenticated);
    const [review, setReview] = React.useState(false);
    const [pay, setPay] = React.useState(false);
    const files = useSelector((state) => state.FileReducer.allFiles.filesDTO);
    const filesCount = useSelector((state) => state.FileReducer.allFiles.totalFileCount);
    const CompanyInvoices = useSelector((state) => state.CompanyReducer.allCompanyInvoices);
    const company = useSelector((state) => state.CompanyReducer.userCompanies[0]);

    const [paginationModel, setPaginationModel] = React.useState({pageSize: 25, page: 0});
    const customerSelect = useSelector((state) => state.UserReducer.customerSelection);

    React.useEffect(() => {
            dispatch({ type: 'GET_COMPANY_FILES', payload: {companyId: company?.id, page: paginationModel.page, pageSize: paginationModel.pageSize, customerId: customerSelect }})
    },[paginationModel]);

    const rowCountRef = React.useRef(filesCount || 0);
    
    const rowCount = React.useMemo(() => {
      if (filesCount !== undefined) {
        rowCountRef.current = filesCount;
      }
      return rowCountRef.current;
    }, [filesCount]);

    const isLoading = useSelector((state) => state.UserReducer.isDataLoading);
    const [edit, setEdit] = React.useState(false);
    const [editRowsModel, setEditRowsModel] = React.useState({});

    /// for checking user data to allow user to download if policy is accepted or not
    const userdata = useSelector((state)=> state.UserReducer.user);
    const pdf = useSelector((state)=> state.FileReducer.PDFfile.file);
    const [pdfUrl, setPdfUrl] = React.useState(`${pdf}`);
    const [pdfStatus, setPdfStatus] = React.useState();
    let user = {
        Id: userdata.id,
        hasAcceptedUploadPolicy: userdata.hasAcceptedUploadPolicy,
        hasAcceptedDownloadPolicy: userdata.hasAcceptedDownloadPolicy,
        hasConfirmedEmail: userdata.hasConfirmedEmail,
        hasReadPrivacyPolicy: userdata.hasReadPrivacyPolicy,
    };

    function showPDF(cellValues) {
        handleOpen();
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [openEstimate, setOpenEstimate] = React.useState(false);
    const [estimateObj, setEstimateObj] = React.useState({});
    function handleOpenEstimate(x){
        setOpenEstimate(true)
        setEstimateObj(x);
        dispatch({ type: 'GET_VALIDATION_FILE', payload: x.id })
    };
    function handleCloseEstimate(){
        setOpenEstimate(false);
        setEstimateObj({});
    };
    const [openBotmarkEstimate, setOpenBotmarkEstimate] = React.useState(false);
    const [botmarkEstimateObj, setBotmarkEstimateObj] = React.useState({});
    function handleOpenBotmarkEstimate(x){
        setOpenBotmarkEstimate(true)
        setBotmarkEstimateObj(x);
        dispatch({ type: 'GET_VALIDATION_FILE', payload: x.id })
    };
    function handleCloseBotmarkEstimate(){
        setOpenBotmarkEstimate(false);
        setBotmarkEstimateObj({});
        dispatch({ type: "VALIDATION_RESET" });
    };

    const [validationView, setValidationView] = React.useState(false);
    function handleOpenValidationView (){
        setValidationView(true);
    };
    function handleCloseValidationView (){
        setValidationView(false);
        dispatch({ type: "VALIDATION_RESET" });
    };

    let userToUpdate = {
        Id: userdata.id,
        hasAcceptedUploadPolicy: userdata.hasAcceptedUploadPolicy,
        hasAcceptedDownloadPolicy: userdata.hasAcceptedDownloadPolicy,
        hasConfirmedEmail: userdata.hasConfirmedEmail,
        hasReadPrivacyPolicy: userdata.hasReadPrivacyPolicy,
    };

    function changePDFStatus(event){
        setPdfStatus(!pdfStatus);
        if (event === true) {
            userToUpdate = {
                Id: userdata.id,
                hasAcceptedUploadPolicy: userdata.hasAcceptedUploadPolicy,
                hasAcceptedDownloadPolicy: 1,
                hasConfirmedEmail: userdata.hasConfirmedEmail,
                hasReadPrivacyPolicy: userdata.hasReadPrivacyPolicy,
            }
            console.log(user)
            // if ( pdfStatus === false){
                dispatch({ type: "UPDATE_AGREEMENT", payload: userToUpdate});
            // }
        }
        else if (event === false) {
            userToUpdate = {
                Id: userdata.id,
                hasAcceptedUploadPolicy: userdata.hasAcceptedUploadPolicy,
                hasAcceptedDownloadPolicy: 0,
                hasConfirmedEmail: userdata.hasConfirmedEmail,
                hasReadPrivacyPolicy: userdata.hasReadPrivacyPolicy,
            }
            console.log(user)
            // if ( pdfStatus === true){
                dispatch({ type: "UPDATE_AGREEMENT", payload: userToUpdate});
            // }        
        }
    };

    React.useEffect(() => {
        dispatch({ type: 'GET_COMPANY_INVOICES', payload: company?.id });
        dispatch({ type: 'GET_PDF_FILE', payload: 2, setPdfUrl });
        dispatch({ type: 'FIND_BOTMARK_PRICES' });

        if (userdata.hasAcceptedDownloadPolicy === 0){
            setPdfStatus(false)
        }
        if (userdata.hasAcceptedDownloadPolicy === 1){
            setPdfStatus(true)
        }
    },[files]);

    const handleDownload = (file) => {
        dispatch({ type: "DOWNLOAD_PAID", payload: file });
    };
    const handleEditRowsModelChange = React.useCallback((model) => {
        setEditRowsModel(model);
    }, []);

    const handleSave = () => {
        console.error(editRowsModel)
        const objId = Object.keys(editRowsModel)[0];
        const obj = Object.values(editRowsModel)[0];
        const item = {
            id: objId,
            Name: obj.name.value
        };
        console.error(item)
        dispatch({type: "UPDATE_FILE_NAME", payload: item});
    };

    const columns = [
        { field: 'name', headerName: 'Project Name', flex: .8, headerClassName: 'header', editable: false},
        { field: 'updated', headerName: 'Updated', flex: .5, headerClassName: 'header', renderCell: (params) => {
            let date = new Date(params.row.updated);
            return date.toLocaleString()
        }},
        { field: 'status', headerName: 'Status', flex: .5, headerClassName: 'header', renderCell: (params) => (
            Status(params.row)
        )},
        { field: 'file', headerName: 'File', flex: .75, headerClassName: 'header', renderCell: (params) => (
            File(params.row)
        )},
        { field: 'Validation', headerName: 'Validation & Summary', flex: .35, headerClassName: 'header', 
            renderCell: (cellValues) => {
                // var role = userRole.theUserRoles[0];
                // if(role == "AD" || role == "PL"){
                //     if(cellValues.row.isAccepted === 1){
                //         return(
                //             <Button variant="contained" sx={{ bgcolor: `info.light`}}
                //                 onClick={() => {
                //                     dispatch({ type: 'GET_VALIDATION_FILE', payload: cellValues.row.id })
                //                     // setPay(!pay)
                //                     handleOpenValidationView();
                //             }}>
                //                 View V & S
                //             </Button>
                //         )
                //     } else{
                //         return(
                //             <Button variant="contained" sx={{ bgcolor: `error.light`}}
                //                 onClick={() => {
                //                     dispatch({ type: 'DELETE_FILE', payload: {fileId:cellValues.row.id, companyId: company.id}})
                //             }}>
                //                 Delete
                //             </Button>                    
                //         )
                //     }
                // } else {
                    if(cellValues.row.isAccepted === 1){
                        return(
                            <Button variant="contained" sx={{ bgcolor: `info.light`}}
                                onClick={() => {
                                    dispatch({ type: 'GET_VALIDATION_FILE', payload: cellValues.row.id })
                                    // setPay(!pay)
                                    handleOpenValidationView();
                            }}>
                                View V & S
                            </Button>
                        )
                    }
                // }
            }
        },
        { field: 'Delete', headerName: 'Delete', flex: .25, headerClassName: 'header', 
            renderCell: (cellValues) => {
                var role = userRole.theUserRoles[0];
                if(role == "AD" || role == "PL"){
                    if(cellValues.row.isAccepted != 1){
                        return(
                            <Button className="delBtn" variant="contained" sx={{ bgcolor: `error.light`}}
                                onClick={() => {
                                    swal({
                                        title: "DELETE FILE",
                                        text: `Delete this file forever? This cannot be undone.`,
                                        icon: "warning",
                                        buttons: true,
                                        dangerMode: false,
                                    })
                                    .then((willUpdate) => {
                                        if (willUpdate) {
                                            dispatch({ type: 'DELETE_FILE', payload: {fileId:cellValues.row.id, companyId: company.id}})
                                        } else {
                                            swal({
                                                title: `File not Deleted.`
                                            });
                                    }})
                            }}>
                                Delete
                            </Button>                    
                        )
                    } else if ( cellValues.row.isAccepted === 1 && cellValues.row.botmarkIsInvoiceAccepted === 1 ){
                        return(
                            <Button className="delBtn" variant="contained" sx={{ bgcolor: `error.light`}}
                                onClick={() => {
                                    swal({
                                        title: "DELETE FILE",
                                        text: `Delete this file forever? This cannot be undone.`,
                                        icon: "warning",
                                        buttons: true,
                                        dangerMode: false,
                                    })
                                    .then((willUpdate) => {
                                        if (willUpdate) {
                                            dispatch({ type: 'DELETE_FILE', payload: {fileId:cellValues.row.id, companyId: company.id}})
                                        } else {
                                            swal({
                                                title: `File not Deleted.`
                                            });
                                    }})
                            }}>
                                Delete
                            </Button>                    
                        )
                    } else if(cellValues.row.isInvoiceAccepted === 3){
                        return(
                            <Button className="delBtn" variant="contained" sx={{ bgcolor: `error.light`}}
                                onClick={() => {
                                    swal({
                                        title: "DELETE FILE",
                                        text: `Delete this file forever? This cannot be undone.`,
                                        icon: "warning",
                                        buttons: true,
                                        dangerMode: false,
                                    })
                                    .then((willUpdate) => {
                                        if (willUpdate) {
                                            dispatch({ type: 'DELETE_FILE', payload: {fileId:cellValues.row.id, companyId: company.id}})
                                        } else {
                                            swal({
                                                title: `File not Deleted.`
                                            });
                                    }})
                            }}>
                                Delete
                            </Button>                     
                        )
                    }
                }
            }
        },
    ];
    function Status(x) {
        if ( x.isProcessed === 0 ) {
            return <Chip icon={<Loader/>} variant="outlined" color="warning" label="PROCESSING"/>
        }
        else if ( x.sequencingErrorCode !== 0 && x.sequencingErrorCode !== 1 && x.isAccepted === 0) {
            return <Chip icon={<AlertCircle/>} variant="outlined" color="error" label="ERROR"/>
        }
        else if ( x.isAccepted === 0 ) {
            return <Chip icon={<Edit2/>} variant="outlined" color="warning" label="APPROVAL"/>
        }
        else if (x.isAccepted === 1 && x.botmarkIsInvoiceAccepted === 1 ) {
            return <Chip icon={<CreditCard/>} variant="outlined" color="secondary" label="BOTMARK ESTIMATE"/>
        }
        else if (x.isAccepted === 1 && x.isInvoiceAccepted === 0 && x.invoiceIsSent === false && x.botmarkIsInvoiceAccepted === 4 ||
            x.isAccepted === 1 && x.isInvoiceAccepted === null && x.invoiceIsSent === null && x.botmarkIsInvoiceAccepted === 4) {
            return <Chip icon={<FileText/>} variant="outlined" color="info" label="CREATE ESTIMATE"/>
        }
        else if (x.isInvoiceAccepted === 1 && x.invoiceIsSent === false) {
            return <Chip icon={<Edit/>} variant="outlined" color="secondary" label="FINISH ESTIMATE"/>
        }
        else if ( x.isInvoiceAccepted === 1 && x.invoiceIsSent === true) {
            return <Chip icon={<RefreshCw/>} variant="outlined" color="secondary" label="PENDING"/>
        }
        else if ( x.isInvoiceAccepted === 3 ) {
            return <Chip icon={<AlertCircle/>} variant="outlined" color="warning" label="ESTIMATE REJECTED"/>
        }
        else if (x.isInvoiceAccepted === 2 ) {
            return <Chip icon={<CheckCircle/>} variant="contained" color="success" label="COMPLETE"/>
        }
        else if (x.botmarkIsInvoiceAccepted === 2 && x.invoiceJobId == null) {
            return <Chip icon={<CheckCircle/>} variant="contained" color="success" label="COMPLETE"/>
        }
    };
    
    function File(x) {
        var role = userRole.theUserRoles[0];
        console.log("ROLE FOUND HERE", role)
        if (role == "AD" || role == "PL"){
            if ( x.isProcessed === 0 
                // && (role == "AD" || "PL") 
                ) {
                return (
                    // disabled still processing
                    <Button disabled variant="contained" color="primary">
                        Validation
                    </Button>
                )
            }
            else if ( x.isAccepted === 0
                // && (role == "AD" || "PL") 
                ) {
                return (
                    // Open modal that allows viewing validation file / downloading file / cancel / accept file
                    <Button variant="contained" color="primary" onClick={() => {
                        dispatch({ type: 'GET_VALIDATION_FILE', payload: x.id })
                        setReview(!review) 
                    }}>
                        Validation
                    </Button>
                )
            }
            else if ( x.isAccepted === 1 && x.botmarkIsInvoiceAccepted === 1 
                // && (role == "AD" || "PL")
            ) {
                return (
                    // Open modal that allows viewing validation file / downloading file / cancel / accept file
                    <Button variant="contained" color="secondary" onClick={() => {
                        dispatch({ type: 'GET_VALIDATION_FILE', payload: x.id })
                        // setPay(!pay)
                        handleOpenBotmarkEstimate(x);
                    }}>
                        Botmark Estimate Review
                    </Button>
                )
            }
            else if ( x.isAccepted === 1 && x.isInvoiceAccepted === 0 && x.invoiceIsSent === false && x.botmarkIsInvoiceAccepted === 4 ||
                x.isAccepted === 1 && x.isInvoiceAccepted === null && x.invoiceIsSent === null && x.botmarkIsInvoiceAccepted === 4
                // && role == ("AD" || "PL") 
            ) {
                return (
                    // brings user to estimate page
                    <Button variant="contained" color="primary" onClick={() => {
                        dispatch({ type: 'GET_VALIDATION_FILE', payload: x.id })
                        // setPay(!pay)
                        handleOpenEstimate(x);
                    }}>
                        Create Estimate
                    </Button>
                )
            }
            else if ( x.isAccepted === 1 && x.isInvoiceAccepted === 1 && x.invoiceIsSent === false && x.botmarkIsInvoiceAccepted === 4 
                    // && role == ("AD" || "PL") 
            ) {
                return (
                    // brings user to estimate page
                    <Button variant="contained" color="secondary" onClick={() => {
                        dispatch({ type: 'GET_VALIDATION_FILE', payload: x.id })
                        // setPay(!pay)
                        handleOpenEstimate(x);
                    }}>
                        Finish Estimate
                    </Button>
                )
            }
            else if ( x.invoiceIsSent === true && x.isInvoiceAccepted === 1 && x.botmarkIsInvoiceAccepted === 4 
                    // && role == ("AD" || "PL") 
                    )
                    {
                return (
                    <Button variant="contained" color="secondary" disabled>
                        PENDING
                    </Button>
                )
            }
            else if ( x.invoiceIsSent === true && x.isInvoiceAccepted === 3 && x.botmarkIsInvoiceAccepted === 4 
                    // && role == ("AD" || "PL") 
                ) {
                return (
                    <Button variant="contained" color="warning" onClick={() => {
                        dispatch({ type: 'GET_VALIDATION_FILE', payload: x.id })
                        // setPay(!pay)
                        handleOpenEstimate(x);
                    }}>
                        Rework Estimate
                    </Button>
                )
            }
            else if (x.invoiceIsSent === true && x.isInvoiceAccepted === 2 && x.botmarkIsInvoiceAccepted === 4){
                return (
                    // shows job has been accepted by the customer
                    <div>     
                        <Button variant="contained" color="secondary" disabled>
                            Job Complete
                        </Button>
                    </div>
                )
            }
            else if (x.botmarkIsInvoiceAccepted === 2 && x.invoiceJobId == null && company.companyStatus == 1){
                return (
                    // download paid file/ accept download policy
                    <div>
                        { userdata.hasAcceptedDownloadPolicy === 0 ?
                            <Button variant="contained" color="warning" onClick={() => showPDF(x)}>
                                Download Policy
                            </Button>
                            :
                            <Button variant="contained" color="success" onClick={() => handleDownload(x)}>
                                Download
                            </Button>
                        }      
                    </div>
                )
            }
            else if ( 
                        // x.botmarkIsInvoiceAccepted === 2 && x.invoiceJobId == null ||
                        // x.botmarkIsInvoiceAccepted === 2 && x.invoiceJobId == null ||
                        x.botmarkIsInvoiceAccepted === 2 && x.invoiceJobId == null && company.companyStatus == 2
                    ){
                return (
                    /// company is locked by Botmark cant download until Botmark resolves
                    <Button variant="contained" color="secondary" disabled>
                        <div>Locked Company: Contact <b>Botweb</b></div>
                    </Button>
                )
            }
        } else {
            if (x.invoiceIsSent === true && x.isInvoiceAccepted === 2 && x.botmarkIsInvoiceAccepted === 4){
                return (
                    // shows job has been accepted by the customer
                    <div>     
                        <Button variant="contained" color="secondary" disabled>
                            Job Complete
                        </Button>
                    </div>
                )
            }
            else if (x.botmarkIsInvoiceAccepted === 2 && x.invoiceJobId == null && company.companyStatus == 1){
                return (
                    // download paid file/ accept download policy
                    <div>
                        { userdata.hasAcceptedDownloadPolicy === 0 ?
                            <Button variant="contained" color="warning" onClick={() => showPDF(x)}>
                                Download Policy
                            </Button>
                            :
                            <Button variant="contained" color="success" onClick={() => handleDownload(x)}>
                                Download
                            </Button>
                        }      
                    </div>
                )
            }
            else if ( 
                        // x.botmarkIsInvoiceAccepted === 2 && x.invoiceJobId == null ||
                        // x.botmarkIsInvoiceAccepted === 2 && x.invoiceJobId == null ||
                        x.botmarkIsInvoiceAccepted === 2 && x.invoiceJobId == null && company.companyStatus == 2
                    ){
                return (
                    /// company is locked by Botmark cant download until Botmark resolves
                    <Button variant="contained" color="secondary" disabled>
                        <div>Locked Company: Contact <b>Botweb</b></div>
                    </Button>
                )
            }
            else {
                return (
                    /// pending 
                    <Button variant="contained" color="secondary" disabled>
                        <div>Waiting for Completion</div>
                    </Button>
                )
            }
        }
    };
    const rows = files;

    console.log("HERE", files);

    function CustomToolBar() {
        return (
            <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #e0e0e0', p: 1 }}>
                <Hidden smDown>
                    <Box width='40%' textAlign="left">
                        <GridToolbarFilterButton/>
                        <GridToolbarColumnsButton/>
                        <GridToolbarDensitySelector/>
                        <GridToolbarExport/>
                    </Box>
                    <Box width='30%'>
                    </Box>
                </Hidden>
                <Hidden smUp>
                </Hidden>
            </GridToolbarContainer>
        )
    };

    return (
        <Box
            sx={{ '& .header': { backgroundColor: '#e2e2e2' }, height: '70vh' }}
        >
            <Typography textAlign={"left"} variant='h4'>
                Plans
            </Typography>
            <ReviewPage status={review} change={setReview} pay={setPay}/>
            {/* <PaymentPage status={pay} change={setPay}/> */}
            {/* <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
                <Typography textAlign={"center"} color={"blue"} variant='h4'>
                    Editing Mode
                </Typography>
                <Typography textAlign={"center"} variant='body2'>
                    Double click to enter row, hit enter or click out to save changes. <br/> Press Esc to cancel editing a field.
                </Typography>
            </Box> */}
            <DataGrid
                components={{
                    Toolbar: CustomToolBar
                }}
                editMode="row"
                onRowEditCommit={handleSave}
                editRowsModel={editRowsModel}
                onEditRowsModelChange={handleEditRowsModelChange}
                columns={columns}
                rows={rows}
                loading={isLoading}
                pageSizeOptions={[10, 25, 50, 100]}
                rowCount={rowCount}
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="pdfModal"
            >
                <div style={{marginBottom: 5+'%'}}>
                    <h3>Downloader Agreement</h3>
                    <p>Read and accept the below User Agreement to download plans.</p>
                    <iframe style={{width: "90%", height: '75vh'}} src={pdfUrl}></iframe>
                    <Form style={{margin: '1%'}}>
                        <Label check>
                            <Input className='pdfCheck' type="switch" role="switch" checked={pdfStatus} onChange={(event) => changePDFStatus(event.target.checked)}/>{' '}
                            Accept Upload Policy
                        </Label>
                    </Form>
                    <p className='pdfNotice'>Click margins to close after accepting</p>
                </div>            
            </Modal>
            <Modal
                open={openEstimate}
                onClose={handleCloseEstimate}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="estimateModal"
                hideBackdrop={true}
            >
                <div className="estimateDiv">
                    <PaymentSummary className="estimateDiv" handleCloseEstimate={handleCloseEstimate} estimateObj={estimateObj}/>
                </div>
            </Modal>
            <Modal
                open={openBotmarkEstimate}
                onClose={handleCloseBotmarkEstimate}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="estimateModal"
                style={{height:'100%', width: '100%'}}
                hideBackdrop={true}
            >
                <div className="estimateDiv" style={{height:'100%', width: '100%', }}>
                    <BotmarkEstimateView className="estimateDiv" handleCloseBotmarkEstimate={handleCloseBotmarkEstimate} botmarkEstimateObj={botmarkEstimateObj}/>
                </div>
            </Modal>
            <Modal
                open={validationView}
                onClose={handleCloseValidationView}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="estimateModal"
                style={{height:'100%', width: '100%'}}
                hideBackdrop={true}
            >
                <div className="estimateDiv" style={{height:'100%', width: '100%', }}>
                    <StaticValidationView className="estimateDiv" handleCloseValidationView={handleCloseValidationView} />
                </div>
            </Modal>
        </Box>
    )
};

export default PlanTable;