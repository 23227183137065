import React from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Link,
  Backdrop,
  CircularProgress,
  Tab,
  Tabs,
  Card,
  CardHeader
} from "@mui/material";
import homeBanner from "../../images/Botmark-Banner-Design-With-Logos.jpg";
import DeanPhoto from "../../images/Dean-Photo.jpeg";
import ArnelPhoto from "../../images/Arnel-Photo.jpeg";
import Youtube from 'react-youtube';
import BlueRulerIcon from "../../images/BlueRulerIcon.png";
import RedPlanIcon from "../../images/RedPlanIcon.png";
import OrangeDeliversIcon from "../../images/OrangeDeliversIcon.png";
import BotmarkFullText from "../../images/BotmarkFullText.png";
import LayoutSpecialtiesLogo from "../../images/LayoutSpecialtiesLogo.png";

function HomePage(){
    const opts = {
        height: '425px',
        width: '100%',
    };

    let screenWidth = screen.width;

    return(
        <div>
            {screenWidth >= 900 ?
                <div>
                    <img style={{width:'100%', boxShadow:" 1px 4px 8px rgba(0, 0, 0, 0.2)"}} src={homeBanner}/>
                </div>
            :
                <div>
                    <img style={{width:"100%"}} src={BotmarkFullText}/>
                    <div style={{display:'flex', flexDirection:'row'}}>
                        <img style={{width:"33%"}} src={RedPlanIcon}/>
                        <img style={{width:"33%"}} src={BlueRulerIcon}/>
                        <img style={{width:"33%"}} src={OrangeDeliversIcon}/>
                    </div>
                    <img style={{width:"50%"}} src={LayoutSpecialtiesLogo}/>
                </div>
            }
        </div>
    )
}

export default HomePage;